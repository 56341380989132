import styled from 'styled-components';
import {BAD_REQUEST, CONFLICT, INTERNAL_SERVER_ERROR, NOT_FOUND} from 'http-status';
import Alert from '@hy-vee/web-core/lib/components/alert/index';
import {spacing} from '@hy-vee/themes/lib';

import {scrollToTopOfModal} from '../../../../utils/view-helpers/view-helpers';
import AlertStatusType from '../../../../enums/alert-status-types';
import {GHOST_VARIANT} from '../../../../enums/alert-variant-types';

const ErrorMessageContainer = styled.div`
    margin-bottom: ${spacing.small};
`;

const saveAddressErrorMessage = {
    [BAD_REQUEST]: (
        <Alert status={AlertStatusType.WARNING_ALERT} variant={GHOST_VARIANT}>
            {'Delivery is currently not available for that address. Please select another address or add a new one.'}
        </Alert>
    ),
    [CONFLICT]: (
        <Alert status={AlertStatusType.ERROR_ALERT} variant={GHOST_VARIANT}>
            {'The address entered is already saved to your account.'}
        </Alert>
    ),
    [INTERNAL_SERVER_ERROR]: (
        <Alert status={AlertStatusType.ERROR_ALERT}>{'An error has occurred. Please try again.'}</Alert>
    ),
    [NOT_FOUND]: (
        <Alert status={AlertStatusType.WARNING_ALERT} variant={GHOST_VARIANT}>
            {
                'Your address was saved, however delivery is currently not available for that saved address. Please select another address or add a new one.'
            }
        </Alert>
    )
};

const DeliveryAddressError = ({status}) => {
    const modal = document.querySelector('.store-selection');

    if (modal) {
        scrollToTopOfModal(modal);
    }

    let message = saveAddressErrorMessage[status];

    if (!message) {
        message = (
            <Alert status={AlertStatusType.WARNING_ALERT} variant={GHOST_VARIANT}>
                {status}
            </Alert>
        );
    }

    return <ErrorMessageContainer>{message}</ErrorMessageContainer>;
};

export default DeliveryAddressError;

import StoreSelectionResult from '@hy-vee/web-store-select/lib/components/store-selection-result/store-selection-result';

import {
    getAddressObjectFromDeliveryAddress,
    getCityStateZip,
    getAddressString
} from '../../../../utils/view-helpers/address-view-helpers';

const MtoDeliveryAddressRow = ({deliveryAddress, onSelectDeliveryAddress}) => {
    const address = getAddressObjectFromDeliveryAddress(deliveryAddress);

    return (
        <StoreSelectionResult
            address={address.streetAddress}
            cityStateZip={getCityStateZip(address)}
            onClick={() =>
                onSelectDeliveryAddress({
                    address: getAddressString(deliveryAddress),
                    deliveryAddressId: deliveryAddress.deliveryAddressId
                })
            }
        />
    );
};

export default MtoDeliveryAddressRow;

import {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import AddressForm from '@hy-vee/web-address-form';
import {Button, SecondaryButton} from '@hy-vee/web-core';

import {GOOGLE_ADDRESS_VERIFICATION} from '../../../../enums/feature-toggle-names';
import FeatureToggleContext from '../../../../context/feature-toggle/context';
import {
    clearAddressSuggestions,
    clearSaveAddressErrorStatus,
    loadAddressSuggestionsForAddress,
    setEnteredDeliveryAddress,
    postNewCustomerDeliveryAddress,
    selectMtoDeliveryAddress,
    setShouldDisplayNewAddressForm,
    setStoreSelectionTypeTab
} from '../../../../action-creators';
import {SET_SAVE_NEW_DELIVERY_ADDRESS_REQUEST} from '../../../../action-types';
import {DEFAULT_VIEW, DELIVERY_TAB, DELIVERY_FULFILLMENT_SELECTION} from '../../../../enums/store-selection-tab-types';
import {VERIFIED} from '../../../../enums/address-verification-level-types';
import {isLoading} from '../../../../utils/view-helpers/loading-indicator-view-helpers';
import {getFulfillmentChangePageUrl} from '../../../../utils/view-helpers/fulfillment-view-helpers';
import {assignParentUrl} from '../../../../utils/iframe-helpers';
import DisableSectionLoader from '../../disable-section-loader';
import DisplayContentContainer from '../../display-content-container';
import {getAddressString, getNewAddressPayloadForSaving} from '../../../../utils/view-helpers/address-view-helpers';

import VerifyNewAddress from './verify-new-address';

const getAddressPayloadForSuggestions = (values) => {
    return {
        addressOne: values.addressOne.trim(),
        addressTwo: (values.addressTwo && values.addressTwo.trim()) || undefined,
        city: values.city,
        state: values.state.value,
        zip: values.zip
    };
};

const handleNewAddress = (actions, deliveryAddress, onSelectDeliveryLocation) => {
    if (deliveryAddress?.fulfillmentLocations?.length > 1) {
        actions.setStoreSelectionTypeTab(DELIVERY_FULFILLMENT_SELECTION);
    } else if (onSelectDeliveryLocation) {
        actions.setStoreSelectionTypeTab(DELIVERY_TAB);
        onSelectDeliveryLocation(deliveryAddress, deliveryAddress.fulfillmentLocations[0].fulfillmentLocationId);
    } else {
        const basketContinuityUrl = getFulfillmentChangePageUrl(
            deliveryAddress.fulfillmentLocations[0].fulfillmentLocationId,
            deliveryAddress.deliveryAddressId
        );

        assignParentUrl(basketContinuityUrl);
    }
};

const cleanup = ({actions, deliveryAddresses}) => {
    actions.setShouldDisplayNewAddressForm(!deliveryAddresses.length);
};

const onCancel = (props) => {
    const {actions, deliveryAddresses, isMto, shouldDisplayNewAddressForm} = props;
    const tab = isMto || (deliveryAddresses.length && shouldDisplayNewAddressForm) ? DELIVERY_TAB : DEFAULT_VIEW;

    actions.clearSaveAddressErrorStatus();
    cleanup(props);
    actions.setStoreSelectionTypeTab(tab);
};

const shouldDisplayAddressSuggestionsForm = (addressSuggestions) =>
    addressSuggestions.verifyLevel && addressSuggestions.verifyLevel !== VERIFIED;

const shouldDisableSection = (loadingIndicators) => isLoading(loadingIndicators, SET_SAVE_NEW_DELIVERY_ADDRESS_REQUEST);

class NewDeliveryAddressForm extends Component {
    componentDidMount() {
        this.props.actions.clearAddressSuggestions();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.deliveryAddress !== this.props.deliveryAddress) {
            if (this.props.isMto) {
                this.props.actions.selectMtoDeliveryAddress({
                    address: getAddressString(this.props.deliveryAddress),
                    deliveryAddressId: this.props.deliveryAddress.deliveryAddressId
                });
            } else if (this.props.deliveryAddress) {
                handleNewAddress(this.props.actions, this.props.deliveryAddress, this.props.onSelectDeliveryLocation);
            }
        } else if (this.props.addressSuggestions.verifyLevel === VERIFIED) {
            this.props.actions.clearAddressSuggestions();

            const addressPayload = getNewAddressPayloadForSaving({
                ...this.props.enteredDeliveryAddress,
                ...this.props.addressSuggestions.addresses[0]
            });

            this.props.actions.postNewCustomerDeliveryAddress(addressPayload);
        }
    }

    componentWillUnmount() {
        cleanup(this.props);
    }

    static contextType = FeatureToggleContext;

    onSubmit = (values, actions) => {
        const useGoogleAddressValidation = this.context.featureEnabled(GOOGLE_ADDRESS_VERIFICATION);

        actions.setEnteredDeliveryAddress({
            ...values,
            addressOne: values.addressOne.trim(),
            addressTwo: (values.addressTwo && values.addressTwo.trim()) || null,
            companyName: values.companyName,
            phone: values.phoneNumber,
            state: values.state.value,
            zip: values.zip
        });

        actions.clearSaveAddressErrorStatus();
        actions.loadAddressSuggestionsForAddress(getAddressPayloadForSuggestions(values), useGoogleAddressValidation);
    };

    render() {
        return (
            <DisableSectionLoader isLoading={shouldDisableSection(this.props.loadingIndicators)}>
                <DisplayContentContainer
                    shouldDisplayContent={shouldDisplayAddressSuggestionsForm(this.props.addressSuggestions)}
                >
                    <VerifyNewAddress />
                </DisplayContentContainer>
                <DisplayContentContainer
                    shouldDisplayContent={!shouldDisplayAddressSuggestionsForm(this.props.addressSuggestions)}
                >
                    <AddressForm
                        PrimaryButton={(props) => (
                            <Button data-testid="address-form-submit-button" {...props}>
                                {'Save & Continue'}
                            </Button>
                        )}
                        SecondaryButton={() => (
                            <SecondaryButton onClick={() => onCancel(this.props)}>{'Go Back'}</SecondaryButton>
                        )}
                        onSubmit={(values) => this.onSubmit(values, this.props.actions)}
                    />
                </DisplayContentContainer>
            </DisableSectionLoader>
        );
    }
}

const mapStateToProps = (state) => ({
    addressSuggestions: state.addressSuggestions,
    deliveryAddress: state.deliveryAddress,
    deliveryAddresses: state.storeSelection.deliveryAddresses,
    enteredDeliveryAddress: state.storeSelection.enteredDeliveryAddress,
    isMto: state.storeSelection.isMto,
    loadingIndicators: state.loadingIndicators,
    shouldDisplayNewAddressForm: state.storeSelection.shouldDisplayNewAddressForm
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            clearAddressSuggestions,
            clearSaveAddressErrorStatus,
            loadAddressSuggestionsForAddress,
            postNewCustomerDeliveryAddress,
            selectMtoDeliveryAddress,
            setEnteredDeliveryAddress,
            setShouldDisplayNewAddressForm,
            setStoreSelectionTypeTab
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(NewDeliveryAddressForm);

import {connect} from 'react-redux';

import {useReservation} from '../../../../hooks/use-reservation';

import MyDeliveryAddresses from './my-delivery-addresses';
import NewDeliveryAddressForm from './new-delivery-address-form';
import DeliveryAddressError from './delivery-address-error';

const sortDeliveryAddressesByOrderHistory = (carts, deliveryAddresses) => {
    const usedDeliveryAddressIds = carts.map((cart) => cart.deliveryAddressId);

    const unusedDeliveryAddresses = deliveryAddresses
        .filter((deliveryAddress) => !usedDeliveryAddressIds.includes(deliveryAddress.deliveryAddressId))
        .sort((firstOrder, secondOrder) => secondOrder.deliveryAddressId - firstOrder.deliveryAddressId);

    const deliveryAddressesByOrderHistory = [...new Set(usedDeliveryAddressIds)]
        .map((deliveryAddressId) =>
            deliveryAddresses.find((address) => address.deliveryAddressId === deliveryAddressId)
        )
        .concat(unusedDeliveryAddresses);

    return deliveryAddressesByOrderHistory.filter((deliveryAddress) => deliveryAddress);
};

const DeliveryBody = ({
    saveAddressErrorStatus,
    cart,
    carts,
    deliveryAddresses,
    shouldDisplayNewAddressForm,
    isReservationStepper
}) => {
    const reservation = useReservation();
    const onSelectDeliveryLocation = reservation?.onSelectDeliveryLocation;

    return (
        <>
            {saveAddressErrorStatus && <DeliveryAddressError status={saveAddressErrorStatus} />}
            {shouldDisplayNewAddressForm ? (
                <NewDeliveryAddressForm onSelectDeliveryLocation={onSelectDeliveryLocation} />
            ) : (
                <MyDeliveryAddresses
                    cart={cart}
                    deliveryAddresses={sortDeliveryAddressesByOrderHistory(carts, deliveryAddresses)}
                    isReservationStepper={isReservationStepper}
                />
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    cart: state.cart,
    carts: state.storeSelection.carts,
    orders: state.orders,
    saveAddressErrorStatus: state.storeSelection.saveAddressErrorStatus,
    shouldDisplayNewAddressForm: state.storeSelection.shouldDisplayNewAddressForm
});

export default connect(mapStateToProps)(DeliveryBody);

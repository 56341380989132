import {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {setStoreSelectionTypeTab, loadStoreSelectionDeliveryPageData} from '../../../../action-creators';
import DeliveryTruckStoreSelection from '../../../icons/delivery-truck-store-selection';
import {isLoading} from '../../../../utils/view-helpers/loading-indicator-view-helpers';
import {SET_STORE_SELECTION_DELIVERY_PAGE_DATA_REQUEST} from '../../../../action-types';
import StoreSelectionModalHeader from '../store-selection-modal-header';
import DisableSectionLoader from '../../disable-section-loader';
import {StyledStoreSelectionModal, StyledFulfillmentSelectionDisplay} from '../styles';

import DeliveryBody from './delivery-body';

const dataIsLoading = (loadingIndicators) =>
    isLoading(loadingIndicators, SET_STORE_SELECTION_DELIVERY_PAGE_DATA_REQUEST);

const getLegacyComponent = (props) => {
    const bodyContent = dataIsLoading(props.loadingIndicators) ? (
        <DisableSectionLoader isLoading />
    ) : (
        <DeliveryBody deliveryAddresses={props.deliveryAddresses} isReservationStepper={props.isReservationStepper} />
    );

    return (
        <StyledFulfillmentSelectionDisplay>
            <StoreSelectionModalHeader
                backAction={props.isMto ? null : props.actions.setStoreSelectionTypeTab}
                description={'Select delivery location'}
                title="Delivery"
            >
                <DeliveryTruckStoreSelection height="40px" width="40px" />
            </StoreSelectionModalHeader>
            <StyledStoreSelectionModal addMargin>{bodyContent}</StyledStoreSelectionModal>
        </StyledFulfillmentSelectionDisplay>
    );
};

class StoreSelectionDeliveryDisplay extends Component {
    componentDidMount() {
        if (!this.props.deliveryAddresses.length) {
            this.props.actions.loadStoreSelectionDeliveryPageData();
        }
    }

    render() {
        const isReservationStepper = this.props.isReservationStepper;

        if (!isReservationStepper) {
            return getLegacyComponent(this.props);
        }

        if (dataIsLoading(this.props.loadingIndicators)) {
            return null;
        }

        return (
            <DeliveryBody
                deliveryAddresses={this.props.deliveryAddresses}
                isReservationStepper={isReservationStepper}
            />
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            loadStoreSelectionDeliveryPageData,
            setStoreSelectionTypeTab
        },
        dispatch
    )
});

const mapStateToProps = (state) => ({
    deliveryAddresses: state.storeSelection.deliveryAddresses,
    isMto: state.storeSelection.isMto,
    loadingIndicators: state.loadingIndicators
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreSelectionDeliveryDisplay);

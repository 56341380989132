import styled from 'styled-components';

const StyledLoadingIndicator = styled.svg`
    animation: spinner-rotator 1.8s linear infinite;
    bottom: 0;
    height: 75px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 75px;
    z-index: 999;

    .path {
        animation:
            spinner-dash 1.8s ease-in-out infinite,
            spinner-colors 1.8s ease-in-out infinite;
        stroke-dasharray: 187;
        stroke-dashoffset: 0;
        transform-origin: center;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        animation: spinner-dash-internet-explorer 1s linear infinite;

        .path {
            stroke: #e21c11;
            stroke-dashoffset: -60;
        }
    }

    @keyframes spinner-dash-internet-explorer {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes spinner-rotator {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(270deg);
        }
    }

    @keyframes spinner-colors {
        0% {
            stroke: #f5f5f5;
        }

        20% {
            stroke: #e21c11;
        }

        50% {
            stroke: #ba140f;
        }

        75% {
            stroke: #cc3000;
        }

        100% {
            stroke: #f5f5f5;
        }
    }

    @keyframes spinner-dash {
        0% {
            stroke-dashoffset: 187;
        }

        50% {
            stroke-dashoffset: 46.75;
            transform: rotate(135deg);
        }

        100% {
            stroke-dashoffset: 187;
            transform: rotate(450deg);
        }
    }
`;

const LoadingIndicator = ({height = '100%', width = '100%', strokeWidth = '3'}) => (
    <StyledLoadingIndicator
        aria-label={'loading'}
        data-testid={'loading'}
        focusable={'false'}
        height={height}
        tabIndex="-1"
        viewBox="0 0 66 66"
        width={width}
    >
        <circle className="path" cx="33" cy="33" fill="none" r="30" strokeLinecap="round" strokeWidth={strokeWidth} />
    </StyledLoadingIndicator>
);

export default LoadingIndicator;

import StoreSelection from '@hy-vee/web-store-select/lib/components/store-selection/store-selection';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import getConfig from 'next/config';

import {setPickupLocationsDistance} from '../../../../action-creators/store-selection/set-pickup-locations-distance-action-creator';
import {clearFilteredPickupStores} from '../../../../action-creators';
import {getMyPickupLocations, hasPickupCarts} from '../../../../utils/store-selection-helpers';
import {
    StyledLocationsRowContainer,
    StyledSearchLocationsContainer,
    StyledStoreSelectionModal,
    StyledSubheading,
    StyledNoResultsMessage
} from '../styles';

import MyPickupLocations from './my-pickup-locations';
import PickupStoreRow from './pickup-store-row';

const {publicRuntimeConfig} = getConfig();

const SEARCH_RADIUS = 35;

const StoreSelectionPickupModalBody = ({pickupLocations, carts, actions}) => {
    const filteredPickupLocations = pickupLocations
        .filter((pickupLocation) => pickupLocation.milesAway <= SEARCH_RADIUS)
        .sort((pickupLocationA, pickupLocationB) => pickupLocationA.milesAway - pickupLocationB.milesAway);

    const pickupLocationsDistanceHasBeenSet =
        pickupLocations.filter((pickupLocation) => pickupLocation.milesAway !== undefined).length > 0;

    const GOOGLE_MAP_API_KEY = publicRuntimeConfig.googleMapsClientApiKey;

    return (
        <StyledStoreSelectionModal>
            {hasPickupCarts(carts) && (
                <MyPickupLocations pickupLocations={getMyPickupLocations(carts, pickupLocations)} />
            )}
            <StyledSearchLocationsContainer>
                <StyledSubheading as="h2">{'Find a Hy-Vee'}</StyledSubheading>
                <StoreSelection
                    autoFocus={!hasPickupCarts(carts)}
                    mapsApiKey={GOOGLE_MAP_API_KEY}
                    onClearResults={actions.clearFilteredPickupStores}
                    onLocationSelect={actions.setPickupLocationsDistance}
                    placeholder={'City, State, or Zip Code'}
                />
                <StyledLocationsRowContainer data-testid="find-a-hy-vee-results">
                    {filteredPickupLocations
                        .filter((pickupLocation) => pickupLocation.milesAway)
                        .map((pickupLocation) => {
                            return (
                                <PickupStoreRow key={pickupLocation.pickupLocationId} pickupLocation={pickupLocation} />
                            );
                        })}
                </StyledLocationsRowContainer>

                {pickupLocationsDistanceHasBeenSet && filteredPickupLocations.length === 0 && (
                    <StyledNoResultsMessage>{`No results within ${SEARCH_RADIUS} miles of your search. Please modify your search criteria and try again.`}</StyledNoResultsMessage>
                )}
            </StyledSearchLocationsContainer>
        </StyledStoreSelectionModal>
    );
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            clearFilteredPickupStores,
            setPickupLocationsDistance
        },
        dispatch
    )
});

const mapStateToProps = (state) => ({
    carts: state.storeSelection.carts,
    pickupLocations: state.storeSelection.pickupLocations
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreSelectionPickupModalBody);

const loadingIndicatorExistsAndIsLoading = (loadingIndicators, loadingIndicatorName) => {
    const loadingIndicator = loadingIndicators.find(
        (loadingIndicatorFromState) => loadingIndicatorFromState.name === loadingIndicatorName
    );

    return loadingIndicator ? loadingIndicator.requestsStarted !== loadingIndicator.requestsCompleted : false;
};

export const hasBeenCalledOnce = (loadingIndicators, loadingIndicatorName) =>
    loadingIndicators.some((loadingIndicator) => loadingIndicator.name === loadingIndicatorName);

export const isLoading = (loadingIndicators, loadingIndicatorName) => {
    if (loadingIndicators.length) {
        return loadingIndicatorExistsAndIsLoading(loadingIndicators, loadingIndicatorName);
    }

    return true;
};

export const isModalRequestLoading = (loadingIndicators, loadingIndicatorName) =>
    loadingIndicatorExistsAndIsLoading(loadingIndicators, loadingIndicatorName);

import {LocationEcommerceStatus} from 'autogen/globalTypes';
import {useFeatureToggle} from 'client/context/feature-toggle';
import {AOH_IN_STORE_ONLY} from 'client/enums/feature-toggle-names';

import {getCityStateZip} from '../../../../utils/view-helpers/address-view-helpers';
import {locationIsDeliveryOnly} from '../../../../utils/view-helpers/fulfillment-view-helpers';
import {useReservation} from '../../../../hooks/use-reservation';
import ReservationStepperStoreSelectionResult from '../../reservation-stepper/reservation-stepper-store-selection-result';

const PickupStoreRow = ({pickupLocation, showMiles = true}) => {
    const {pickupLocation: selectedPickupLocation, onSelectPickupLocation} = useReservation();
    const {featureEnabled} = useFeatureToggle();
    const isInStoreOnlyEnabled = featureEnabled(AOH_IN_STORE_ONLY);

    const isSelected =
        selectedPickupLocation &&
        (selectedPickupLocation.storeId
            ? Number(selectedPickupLocation.storeId) === Number(pickupLocation.fulfillmentStoreId)
            : Number(selectedPickupLocation.fulfillmentLocationId) === Number(pickupLocation.fulfillmentLocationId));

    return (
        <ReservationStepperStoreSelectionResult
            address={pickupLocation.address}
            cityStateZip={getCityStateZip(pickupLocation)}
            hasPasscode={pickupLocation.hasPasscode}
            isDeliveryOnly={locationIsDeliveryOnly(pickupLocation.fulfillmentStoreLocation?.ecommerceStatus)}
            milesAway={pickupLocation.milesAway}
            name={pickupLocation.name}
            onClick={() => onSelectPickupLocation(pickupLocation)}
            selected={isSelected}
            shopInStoreOnly={
                isInStoreOnlyEnabled &&
                pickupLocation.fulfillmentStoreLocation?.ecommerceStatus === LocationEcommerceStatus.INACTIVE
            }
            showMiles={showMiles}
            target="_top"
        />
    );
};

export default PickupStoreRow;

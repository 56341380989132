import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import VerifyAddress from '@hy-vee/web-address-form/lib/components/verify-address';

import {
    clearAddressSuggestions,
    postNewCustomerDeliveryAddress,
    clearSaveAddressErrorStatus,
    setAddressErrorStatus
} from '../../../../action-creators';
import {STREET_PARTIAL, PREMISES_PARTIAL} from '../../../../enums/address-verification-level-types';
import {PREMESIS_PARTIAL_MESSAGE, STREET_PARTIAL_MESSAGE} from '../../../../enums/address-verification-messages';
import {getNewAddressPayloadForSaving} from '../../../../utils/view-helpers/address-view-helpers';

const saveCustomerAddressIfSelected = (address, actions, enteredDeliveryAddress) => {
    if (address.addressOne) {
        actions.clearSaveAddressErrorStatus();
        actions.clearAddressSuggestions();

        const newAddress = getNewAddressPayloadForSaving({
            ...enteredDeliveryAddress,
            ...address
        });

        actions.postNewCustomerDeliveryAddress(newAddress);
    } else {
        actions.setAddressErrorStatus('Please select an address to save.');
    }
};

const navigateBack = (actions) => {
    actions.clearSaveAddressErrorStatus();
    actions.clearAddressSuggestions();
};

const verificationMessages = {
    [PREMISES_PARTIAL]: PREMESIS_PARTIAL_MESSAGE,
    [STREET_PARTIAL]: STREET_PARTIAL_MESSAGE
};

const VerifyNewAddress = ({actions, addressSuggestions, enteredDeliveryAddress}) => (
    <VerifyAddress
        enteredAddress={enteredDeliveryAddress}
        name={`${enteredDeliveryAddress.firstName} ${enteredDeliveryAddress.lastName}`}
        noResultsMessage={verificationMessages[addressSuggestions.verifyLevel]}
        onNavigateBack={() => navigateBack(actions)}
        onSelect={(address) => saveCustomerAddressIfSelected(address, actions, enteredDeliveryAddress)}
        verifiedAddresses={addressSuggestions.addresses || []}
    />
);

const mapStateToProps = (state) => ({
    addressSuggestions: state.addressSuggestions,
    enteredDeliveryAddress: state.storeSelection.enteredDeliveryAddress
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            clearAddressSuggestions,
            clearSaveAddressErrorStatus,
            postNewCustomerDeliveryAddress,
            setAddressErrorStatus
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyNewAddress);

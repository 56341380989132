import StoreSelectionResult from '@hy-vee/web-store-select/lib/components/store-selection-result/store-selection-result';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {
    getAddressObjectFromDeliveryAddress,
    getCityStateZip
} from '../../../../utils/view-helpers/address-view-helpers';
import {setSelectedDeliveryAddress, setStoreSelectionTypeTab} from '../../../../action-creators';
import {DELIVERY_FULFILLMENT_SELECTION} from '../../../../enums/store-selection-tab-types';
import {getFulfillmentChangePageUrl} from '../../../../utils/view-helpers/fulfillment-view-helpers';
import MtoDeliveryAddressRow from '../mto/mto-delivery-address-row';
import {selectMtoDeliveryAddress} from '../../../../action-creators/store-selection/select-mto-delivery-address-action-creator';
import {useReservation} from '../../../../hooks/use-reservation';
import ReservationStepperStoreSelectionResult from '../../reservation-stepper/reservation-stepper-store-selection-result';

const displayFulfillmentLocationOptionsForDelivery = (actions, deliveryAddress) => {
    actions.setSelectedDeliveryAddress(deliveryAddress);
    actions.setStoreSelectionTypeTab(DELIVERY_FULFILLMENT_SELECTION);
};

const DeliveryAddressRow = ({actions, deliveryAddress, cart, isMto, isReservationStepper, shopInStoreOnly}) => {
    const address = getAddressObjectFromDeliveryAddress(deliveryAddress);
    const reservation = useReservation();
    const selectedDeliveryLocation = reservation?.deliveryLocation;
    const onSelectDeliveryLocation = reservation?.onSelectDeliveryLocation;

    if (isMto) {
        return (
            <MtoDeliveryAddressRow
                deliveryAddress={deliveryAddress}
                onSelectDeliveryAddress={actions.selectMtoDeliveryAddress}
            />
        );
    }

    const isSelected =
        cart.deliveryAddressId === deliveryAddress.deliveryAddressId &&
        cart.fulfillmentLocationId === deliveryAddress.fulfillmentLocations[0].fulfillmentLocationId;
    const isReservationSelected =
        selectedDeliveryLocation &&
        Number(selectedDeliveryLocation.deliveryAddressId) === Number(deliveryAddress.deliveryAddressId);

    if (deliveryAddress.fulfillmentLocations.length > 1) {
        if (isReservationStepper) {
            return (
                <ReservationStepperStoreSelectionResult
                    address={address.streetAddress}
                    cityStateZip={getCityStateZip(address)}
                    onClick={() => displayFulfillmentLocationOptionsForDelivery(actions, deliveryAddress)}
                    selected={isReservationSelected}
                    shopInStoreOnly={shopInStoreOnly}
                />
            );
        }

        return (
            <StoreSelectionResult
                address={address.streetAddress}
                cityStateZip={getCityStateZip(address)}
                onClick={() => displayFulfillmentLocationOptionsForDelivery(actions, deliveryAddress)}
            />
        );
    }

    if (isReservationStepper) {
        return (
            <ReservationStepperStoreSelectionResult
                address={address.streetAddress}
                cityStateZip={getCityStateZip(address)}
                onClick={() =>
                    onSelectDeliveryLocation(
                        deliveryAddress,
                        deliveryAddress.fulfillmentLocations[0].fulfillmentLocationId
                    )
                }
                selected={isReservationSelected}
                shopInStoreOnly={shopInStoreOnly}
                target="_top"
            />
        );
    }

    return (
        <StoreSelectionResult
            address={address.streetAddress}
            cityStateZip={getCityStateZip(address)}
            href={getFulfillmentChangePageUrl(
                deliveryAddress.fulfillmentLocations[0].fulfillmentLocationId,
                deliveryAddress.deliveryAddressId
            )}
            selected={isSelected}
            shopInStoreOnly={shopInStoreOnly}
            target="_top"
        />
    );
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            selectMtoDeliveryAddress,
            setSelectedDeliveryAddress,
            setStoreSelectionTypeTab
        },
        dispatch
    )
});

const mapStateToProps = (state) => ({
    isMto: state.storeSelection.isMto
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryAddressRow);

import {FC, PropsWithChildren} from 'react';
import {useQuery} from '@apollo/client';

import {StyledLocationsRowContainer} from '../styles';
import {
    getDeliveryAddressesByCustomerIdVariables,
    getDeliveryAddressesByCustomerId as getDeliveryAddressData
} from '../../../../../autogen/getDeliveryAddressesByCustomerId';
import {useCustomer} from '../../../../hooks/customer-hooks';
import {getDeliveryAddressesByCustomerId} from '../../../../graphql/queries/delivery-address-queries';
import {graphqlClient} from '../../../../graphql/graphql-client';
import {useFeatureToggle} from '../../../../context/feature-toggle';
import {locationIsIneligible} from '../../../../utils/view-helpers/fulfillment-view-helpers';

import DeliveryAddressRow from './delivery-address-row';

interface IDeliveryAddressFromREST {
    deliveryAddressId: Number;
}

interface ICustomerCart {
    deliveryAddressId: Number;
    fulfillmentLocationId: Number;
}

interface IDeliveryAddressRowsDisplay {
    isReservationStepper: boolean;
    cart: ICustomerCart;
    deliveryAddresses: IDeliveryAddressFromREST[];
}

const DeliveryAddressRowsDisplay: FC<PropsWithChildren<PropsWithChildren<IDeliveryAddressRowsDisplay>>> = ({
    isReservationStepper,
    cart,
    deliveryAddresses
}) => {
    const {customerId} = useCustomer();

    const {featureEnabled} = useFeatureToggle();

    const {data: deliveryData} = useQuery<getDeliveryAddressData, getDeliveryAddressesByCustomerIdVariables>(
        getDeliveryAddressesByCustomerId,
        {
            client: graphqlClient(),
            skip: !customerId,
            variables: {
                customerId: customerId || 0
            }
        }
    );

    return (
        <StyledLocationsRowContainer>
            {deliveryAddresses.map((deliveryAddress) => {
                const matchingDeliveryAddressFromGraphql = deliveryData?.deliveryAddresses?.find(
                    (deliveryAddressFromGraphql) =>
                        Number(deliveryAddressFromGraphql.deliveryAddressId) === deliveryAddress.deliveryAddressId
                );

                let shopInStoreOnly = true;

                if (
                    matchingDeliveryAddressFromGraphql?.fulfillmentLocations &&
                    matchingDeliveryAddressFromGraphql.fulfillmentLocations.length &&
                    matchingDeliveryAddressFromGraphql.fulfillmentLocations[0].fulfillmentStoreLocation
                ) {
                    shopInStoreOnly = locationIsIneligible(
                        matchingDeliveryAddressFromGraphql.fulfillmentLocations[0].fulfillmentStoreLocation
                            .ecommerceStatus,
                        featureEnabled
                    );
                }

                return (
                    <DeliveryAddressRow
                        cart={cart}
                        deliveryAddress={deliveryAddress}
                        isReservationStepper={isReservationStepper}
                        key={Number(deliveryAddress.deliveryAddressId)}
                        shopInStoreOnly={shopInStoreOnly}
                    />
                );
            })}
        </StyledLocationsRowContainer>
    );
};

export default DeliveryAddressRowsDisplay;

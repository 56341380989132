import styled, {css} from 'styled-components';

import LoadingIndicator from '../icons/loading-indicator';

const StyledSectionLoader = styled.div`
    ${(props) =>
        props.isLoading &&
        css`
            height: 100%;
            position: relative;
            width: 100%;
        `};

    ${(props) =>
        props.addMinHeight &&
        css`
            min-height: ${props.minHeight}px;
        `};
`;

const StyledTransparentOverlay = styled.div`
    background: ${(props) => props.backgroundColor};
    height: 100%;
    opacity: 0.6;
    position: absolute;
    width: 100%;
    z-index: 1;
`;

const DisableSectionLoader = ({addMinHeight, backgroundColor, minHeight, children, isLoading}) => (
    <StyledSectionLoader
        addMinHeight={addMinHeight}
        data-testid={'disable-section-loader'}
        isLoading={isLoading}
        minHeight={minHeight}
    >
        {isLoading && (
            <>
                <LoadingIndicator />
                <StyledTransparentOverlay backgroundColor={backgroundColor} />
            </>
        )}
        {children}
    </StyledSectionLoader>
);

DisableSectionLoader.defaultProps = {
    addMinHeight: true,
    backgroundColor: '#fff',
    children: null,
    minHeight: 300
};

export default DisableSectionLoader;

import {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {
    setStoreSelectionTypeTab,
    loadStoreSelectionPickupPageData,
    clearFilteredPickupStores
} from '../../../../action-creators';
import {SET_STORE_SELECTION_PICKUP_PAGE_DATA_REQUEST} from '../../../../action-types';
import {isLoading} from '../../../../utils/view-helpers/loading-indicator-view-helpers';
import DisableSectionLoader from '../../disable-section-loader';

import StoreSelectionPickupModalBody from './store-selection-pickup-modal-body';

const shouldDisableSection = (loadingIndicators) =>
    isLoading(loadingIndicators, SET_STORE_SELECTION_PICKUP_PAGE_DATA_REQUEST);

class StoreSelectionPickupDisplay extends Component {
    componentDidMount() {
        this.props.actions.clearFilteredPickupStores();

        if (!this.props.pickupLocations.length) {
            this.props.actions.loadStoreSelectionPickupPageData();
        }
    }

    render() {
        return shouldDisableSection(this.props.loadingIndicators) ? (
            <DisableSectionLoader isLoading />
        ) : (
            <StoreSelectionPickupModalBody />
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            clearFilteredPickupStores,
            loadStoreSelectionPickupPageData,
            setStoreSelectionTypeTab
        },
        dispatch
    )
});

const mapStateToProps = (state) => ({
    loadingIndicators: state.loadingIndicators,
    pickupLocations: state.storeSelection.pickupLocations
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreSelectionPickupDisplay);

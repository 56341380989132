import {useDispatch} from 'react-redux';
import StoreSelectionResult from '@hy-vee/web-store-select/lib/components/store-selection-result/store-selection-result';

import {getCityStateZip} from '../../../../utils/view-helpers/address-view-helpers';
import {getFulfillmentChangePageUrl} from '../../../../utils/view-helpers/fulfillment-view-helpers';
import {useReservation} from '../../../../hooks/use-reservation';
import {DELIVERY_TAB} from '../../../../enums/store-selection-tab-types';
import {setStoreSelectionTypeTab} from '../../../../action-creators';
import ReservationStepperStoreSelectionResult from '../../reservation-stepper/reservation-stepper-store-selection-result';

const DeliveryFulfillmentSelectionRow = ({cart, deliveryAddress, fulfillmentLocation, store, isReservationStepper}) => {
    const reservation = useReservation();
    const onSelectDeliveryLocation = reservation?.onSelectDeliveryLocation;

    const dispatch = useDispatch();

    if (isReservationStepper) {
        const isSelected =
            Number(reservation.fulfillmentLocationId) === Number(fulfillmentLocation.fulfillmentLocationId);

        return (
            <ReservationStepperStoreSelectionResult
                address={store.address}
                cityStateZip={getCityStateZip(store)}
                name={store.name}
                onClick={() => {
                    dispatch(setStoreSelectionTypeTab(DELIVERY_TAB));
                    onSelectDeliveryLocation(deliveryAddress, fulfillmentLocation.fulfillmentLocationId);
                }}
                selected={isSelected}
                target="_top"
            />
        );
    }

    const isSelected = cart.fulfillmentLocationId === fulfillmentLocation.fulfillmentLocationId;

    return (
        <StoreSelectionResult
            address={store.address}
            cityStateZip={getCityStateZip(store)}
            href={getFulfillmentChangePageUrl(
                fulfillmentLocation.fulfillmentLocationId,
                deliveryAddress.deliveryAddressId
            )}
            name={store.name}
            selected={isSelected}
            target="_top"
        />
    );
};

export default DeliveryFulfillmentSelectionRow;

import {LinkButton} from '@hy-vee/web-core/lib/components/button';

import {DEFAULT_VIEW} from '../../../enums/store-selection-tab-types';

import {StyledModalDescription, StyledModalHeader} from './styles';

const StoreSelectionModalHeader = ({title, description, backAction, children}) => (
    <StyledModalHeader data-testid={'storeSelection'}>
        {children}
        <div>
            {title}
            {backAction && (
                <>
                    {' | '}
                    <LinkButton onClick={() => backAction(DEFAULT_VIEW)} size={'large'}>
                        {'change'}
                    </LinkButton>
                </>
            )}
            <StyledModalDescription>{description}</StyledModalDescription>
        </div>
    </StyledModalHeader>
);

export default StoreSelectionModalHeader;

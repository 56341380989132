import {useEffect} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {bindActionCreators} from 'redux';
import {SecondaryButton, LinkButton} from '@hy-vee/web-core/lib/components/button';
import Alert from '@hy-vee/web-core/lib/components/alert';
import {WarningIcon} from '@hy-vee/icons';

import {setStoreSelectionTypeTab, setSelectedDeliveryAddress} from '../../../../action-creators';
import {DELIVERY_TAB} from '../../../../enums/store-selection-tab-types';
import DeliveryTruckStoreSelection from '../../../icons/delivery-truck-store-selection';
import StoreSelectionModalHeader from '../store-selection-modal-header';
import {
    StyledButtonContainer,
    StyledDeliverySelection,
    StyledLocationsRowContainer,
    StyledFulfillmentSelectionDisplay
} from '../styles';
import {GHOST_VARIANT} from '../../../../enums/alert-variant-types';

import DeliveryFulfillmentSelectionRow from './delivery-fulfillment-selection-row';

const StyledWarning = styled(WarningIcon)`
    margin-right: 8px;
`;

const StyledLinkButton = styled(LinkButton)`
    margin-left: 8px;
`;

const StyledWarningContainer = styled.div`
    display: flex;
    text-align: left;
`;

const StoreSelectionDeliveryFulfillmentSelectionDisplay = ({
    actions,
    cart,
    deliveryAddress,
    stores,
    isReservationStepper
}) => {
    const cleanUp = () => {
        actions.setSelectedDeliveryAddress({});
        actions.setStoreSelectionTypeTab(DELIVERY_TAB);
    };

    useEffect(() => cleanUp);

    return (
        <StyledFulfillmentSelectionDisplay>
            {!isReservationStepper && (
                <StoreSelectionModalHeader
                    backAction={actions.setStoreSelectionTypeTab}
                    description={'Select delivery fulfillment'}
                    title="Delivery"
                >
                    <DeliveryTruckStoreSelection height="40px" width="40px" />
                </StoreSelectionModalHeader>
            )}
            <StyledDeliverySelection isReservationStepper={isReservationStepper}>
                {!deliveryAddress?.fulfillmentLocations?.length ? (
                    <StyledWarningContainer>
                        <StyledWarning />
                        <strong>{"We're sorry, something went wrong."}</strong>
                        <StyledLinkButton onClick={cleanUp}>{'Please try again'}</StyledLinkButton>
                    </StyledWarningContainer>
                ) : (
                    <>
                        <Alert status="warning" variant={GHOST_VARIANT}>
                            {'Multiple locations deliver to this address. Please select a location.'}
                        </Alert>
                        <StyledLocationsRowContainer>
                            {deliveryAddress.fulfillmentLocations.map((fulfillmentLocation) => (
                                <DeliveryFulfillmentSelectionRow
                                    cart={cart}
                                    deliveryAddress={deliveryAddress}
                                    fulfillmentLocation={fulfillmentLocation}
                                    isReservationStepper={isReservationStepper}
                                    key={fulfillmentLocation.fulfillmentLocationId}
                                    store={stores.find(
                                        (store) => store.storeId === fulfillmentLocation.fulfillmentStoreId
                                    )}
                                />
                            ))}
                        </StyledLocationsRowContainer>
                        <StyledButtonContainer>
                            <SecondaryButton block onClick={cleanUp}>
                                {'Go Back'}
                            </SecondaryButton>
                        </StyledButtonContainer>
                    </>
                )}
            </StyledDeliverySelection>
        </StyledFulfillmentSelectionDisplay>
    );
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            setSelectedDeliveryAddress,
            setStoreSelectionTypeTab
        },
        dispatch
    )
});

const mapStateToProps = (state) => ({
    cart: state.cart,
    deliveryAddress: state.deliveryAddress,
    stores: state.storeSelection.stores
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreSelectionDeliveryFulfillmentSelectionDisplay);

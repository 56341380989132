import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {AddCircleIcon} from '@hy-vee/icons';
import {LinkButton} from '@hy-vee/web-core/lib/components/button';
import {hyvee} from '@hy-vee/themes';

import {
    clearSaveAddressErrorStatus,
    setShouldDisplayNewAddressForm,
    setShouldShowAllMyAddresses
} from '../../../../action-creators';
import {ACCOUNT_ADDRESSES_PATH} from '../../../../enums/rerouting-paths';
import {StyledSubheading, StyledNoteContainer, StyledIconContainer, StyledHeaderSection} from '../styles';

import DeliveryAddressRowsDisplay from './delivery-address-rows-display';

const MyDeliveryAddresses = ({actions, shouldShowAllMyAddresses, cart, deliveryAddresses, isReservationStepper}) => {
    const deliveryAddressesToDisplay = shouldShowAllMyAddresses ? deliveryAddresses : deliveryAddresses.slice(0, 2);

    return (
        <div>
            <StyledHeaderSection>
                <StyledSubheading as="h2">{'My Delivery Addresses'}</StyledSubheading>
                <LinkButton
                    data-testid="add-address-button"
                    onClick={() => {
                        actions.setShouldDisplayNewAddressForm(true);
                        actions.clearSaveAddressErrorStatus();
                    }}
                >
                    <StyledIconContainer>
                        <AddCircleIcon color={hyvee.primary} size={'small'} />
                    </StyledIconContainer>
                    {'Add Address'}
                </LinkButton>
            </StyledHeaderSection>
            <DeliveryAddressRowsDisplay
                cart={cart}
                deliveryAddresses={deliveryAddressesToDisplay}
                isReservationStepper={isReservationStepper}
            />
            {deliveryAddresses.length > 2 && !shouldShowAllMyAddresses && (
                <LinkButton onClick={() => actions.setShouldShowAllMyAddresses(true)}>
                    {'View All of My Addresses'}
                </LinkButton>
            )}
            <StyledNoteContainer>
                {'Only addresses that are eligible for delivery will display. View all addresses in '}
                <LinkButton as="a" href={ACCOUNT_ADDRESSES_PATH} rel="noopener noreferrer" target="_blank">
                    {'account preferences'}
                </LinkButton>
                {'.'}
            </StyledNoteContainer>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            clearSaveAddressErrorStatus,
            setShouldDisplayNewAddressForm,
            setShouldShowAllMyAddresses
        },
        dispatch
    )
});

const mapStateToProps = (state) => ({
    shouldShowAllMyAddresses: state.storeSelection.shouldShowAllMyAddresses
});

export default connect(mapStateToProps, mapDispatchToProps)(MyDeliveryAddresses);

import styled from 'styled-components';
import {spacing, colors} from '@hy-vee/themes/lib';

const StyledDeliveryTruck = styled.svg`
    margin-right: ${spacing.medium};
`;

const DeliveryTruckStoreSelection = ({height, width}) => (
    <StyledDeliveryTruck
        data-testid="DeliveryTruckStoreSelection_icon"
        focusable={'false'}
        height={height}
        tabIndex="-1"
        viewBox="0 0 32 32"
        width={width}
    >
        <path
            d="m29.575 11.2c-.475-.2-.95-.4-1.15-.6-.275-.275-.775-1.625-1.125-2.6-.925-2.5-1.325-3.375-2.025-3.375h-4.875v-4.075c0-.3-.25-.55-.55-.55h-15.15c-.3 0-.55.25-.55.55v2.125c0 .3.25.55.55.55s.55-.25.55-.55v-1.55h14.025v16.525h-14.025v-1.05c0-.3-.25-.55-.55-.55s-.55.25-.55.55v7.725c0 .3.25.55.55.55h3.3c.275 1.725 1.775 3.05 3.575 3.05s3.3-1.325 3.575-3.05h6.025c.275 1.725 1.775 3.05 3.575 3.05s3.3-1.325 3.575-3.05h3.125c.3 0 .55-.25.55-.55v-10.75c0-1.325-1.35-1.9-2.425-2.375zm-9.175-5.425h4.7c.275.375.775 1.725 1.125 2.625.6 1.6 1 2.6 1.4 3 .35.35.9.6 1.5.85.825.35 1.75.75 1.75 1.35v4.075h-10.475zm-8.85 21.075c-1.375 0-2.5-1.125-2.5-2.5s1.125-2.5 2.5-2.5 2.5 1.125 2.5 2.5-1.125 2.5-2.5 2.5zm13.2 0c-1.375 0-2.5-1.125-2.5-2.5s1.125-2.5 2.5-2.5 2.5 1.125 2.5 2.5-1.125 2.5-2.5 2.5zm3.575-3.075c-.275-1.725-1.775-3.05-3.575-3.05s-3.3 1.325-3.575 3.05h-6.05c-.275-1.725-1.775-3.05-3.575-3.05s-3.3 1.325-3.575 3.05h-2.725v-5h25.625v5zm-19.6-10.9c0 .3-.25.55-.575.55h-4.825c-.3 0-.55-.25-.55-.55s.25-.55.55-.55h4.85c.3 0 .55.25.55.55zm0-6.975c0 .325-.25.575-.575.55h-7.6c-.3 0-.55-.25-.55-.55s.25-.55.55-.55h7.625c.3 0 .55.25.55.55zm0 3.5c0 .3-.25.55-.55.55h-6.225c-.3 0-.55-.25-.55-.55s.25-.55.55-.55h6.225c.3 0 .55.25.55.55z"
            fill={colors.grey[500]}
            fillRule="evenodd"
            transform="translate(0 2)"
        />
    </StyledDeliveryTruck>
);

export default DeliveryTruckStoreSelection;

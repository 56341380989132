import {PICKUP_FULFILLMENT_TYPES} from '@hy-vee/shared-utils-aisles-online';

const pickupCartOnly = (cart) => PICKUP_FULFILLMENT_TYPES.includes(cart.fulfillmentType);

export const hasPickupCarts = (carts) => carts.some(pickupCartOnly);

export const getMyPickupLocations = (carts, pickupLocations) => {
    const getPickupLocationByFulfillmentLocationId = (locations, fulfillmentLocationId) =>
        locations.filter((location) => location.fulfillmentLocationId === fulfillmentLocationId);

    return [...new Set(carts.filter(pickupCartOnly).map((cart) => cart.fulfillmentLocationId))].reduce(
        (previousPickupLocationAccumulator, fulfillmentLocationId) =>
            previousPickupLocationAccumulator.concat(
                getPickupLocationByFulfillmentLocationId(pickupLocations, fulfillmentLocationId)
            ),
        []
    );
};

export const getStoreFromCartForHeaderPage = (carts) =>
    carts?.[0].fulfillmentLocation
        ? {
              id: carts[0].fulfillmentLocation.fulfillmentStoreId,
              name: carts[0].fulfillmentLocation.locationName
          }
        : {};

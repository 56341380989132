import {connect} from 'react-redux';
import {LinkButton} from '@hy-vee/web-core/lib/components/button';
import {bindActionCreators} from 'redux';

import {setShouldShowAllMyLocations} from '../../../../action-creators';
import {StyledSubheading, StyledLocationsRowContainer} from '../styles';

import PickupStoreRow from './pickup-store-row';

const MyPickupLocations = ({actions, shouldShowAllMyLocations, pickupLocations}) => {
    const pickupLocationsToDisplay = shouldShowAllMyLocations ? pickupLocations : pickupLocations.slice(0, 2);

    return (
        <div>
            <StyledSubheading as="h2">{'My Pickup Locations'}</StyledSubheading>
            <StyledLocationsRowContainer>
                {pickupLocationsToDisplay.map((pickupLocation) => (
                    <PickupStoreRow
                        key={pickupLocation.pickupLocationId}
                        pickupLocation={pickupLocation}
                        showMiles={false}
                    />
                ))}
            </StyledLocationsRowContainer>
            {pickupLocations.length > 2 && !shouldShowAllMyLocations && (
                <LinkButton onClick={() => actions.setShouldShowAllMyLocations(true)}>
                    {'View All of My Locations'}
                </LinkButton>
            )}
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            setShouldShowAllMyLocations
        },
        dispatch
    )
});

const mapStateToProps = (state) => ({
    shouldShowAllMyLocations: state.storeSelection.shouldShowAllMyLocations
});

export default connect(mapStateToProps, mapDispatchToProps)(MyPickupLocations);
